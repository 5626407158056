import React, { Component } from 'react';

import FarceActions from 'farce/lib/Actions';
import BrowserProtocol from 'farce/lib/BrowserProtocol';
import createHistoryEnhancer from 'farce/lib/createHistoryEnhancer';
import queryMiddleware from 'farce/lib/queryMiddleware';
import createConnectedRouter from 'found/lib/createConnectedRouter';
import createMatchEnhancer from 'found/lib/createMatchEnhancer';
import createRender from 'found/lib/createRender';
import Matcher from 'found/lib/Matcher';
import resolver from 'found/lib/resolver';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import 'js/shared/stats';

import Reset from 'js/styled/reset';
import {
  fetchFeatureFlags,
  fetchUserAndSiteData,
  loadApiV2Config,
} from '~/apps/Customer/global.actions';
import reducers from '~/apps/Customer/reducers';
import customerRoutes from '~/apps/Customer/routes';
import { getLocale, getMessagesForLocale } from '~/helpers/intl';

const apiConfig = {
  api: {
    token: window.VHX.config.token,
  },
  urls: {
    root: window.VHX.config.root_url,
    api_v1: window.VHX.config.api_url,
  },
};

class CustomerApp extends Component {
  render() {
    return (
      <IntlProvider
        locale={getLocale()}
        messages={getMessagesForLocale(getLocale())}
        textComponent='span'
      >
        <Reset />
        {this.props.children}
      </IntlProvider>
    );
  }
}

const routeConfig = [
  {
    path: '/',
    Component: CustomerApp,
    getData: async ({ context }) => {
      await context.store.dispatch(fetchFeatureFlags());
      await context.store.dispatch(loadApiV2Config());
      if (
        isNil(context.store.getState().global.current.site) ||
        isNil(context.store.getState().global.current.customer)
      ) {
        await context.store.dispatch(fetchUserAndSiteData());
      }
    },
    children: customerRoutes,
  },
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers(reducers),
  composeEnhancers(
    createHistoryEnhancer({
      protocol: new BrowserProtocol(),
      middlewares: [queryMiddleware],
    }),
    createMatchEnhancer(new Matcher(routeConfig)),
    applyMiddleware(thunk.withExtraArgument(apiConfig)),
  ),
);

store.dispatch(FarceActions.init());

const Error = ({ error }) => <div>{error}</div>;
Error.propTypes = {
  error: PropTypes.string,
};

const ConnectedRouter = createConnectedRouter({
  render: createRender({
    renderError: Error,
  }),
});

CustomerApp.propTypes = {
  children: PropTypes.node,
};

CustomerApp.defaultProps = {
  children: <div />,
};

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter resolver={resolver} matchContext={{ store }} />
  </Provider>,
  document.getElementById('customer-app'),
);

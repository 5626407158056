import Redirect from 'found/lib/Redirect';
import isNil from 'lodash/isNil';
import { isEmpty } from 'ramda';

import {
  getGiftPath,
  getParam,
  getPurchasePath,
  getReceiptPath,
  getSignupPath,
  isFvod,
} from '~/apps/Customer/pages/checkout/CheckoutHelpers';
import checkoutRoutes from '~/apps/Customer/pages/checkout/routes';

export const customerIsLoggedIn = (customer) => !isEmpty(customer);
export const giftIsSelected = (props) =>
  !isNil(props.purchaseOption.gift_label);
export const customerHasPlan = (customer) => customer.plan !== 'none';
export const customerHasNoPlan = (customer) => customer.plan === 'none';

export const initialForm = ({ context }) => {
  const { site, hasPurchasedProduct } = context.store.getState().global.current;
  const product = context.store.getState().checkout.product;

  if (getParam('gift') === '1') {
    return getGiftPath(product);
  }

  if (!hasPurchasedProduct) {
    return isFvod(site, product)
      ? getSignupPath(product)
      : getPurchasePath(product);
  } else {
    return getReceiptPath(product);
  }
};

const customerRoutes = [
  new Redirect({
    from: 'checkout',
    to: initialForm,
  }),
  new Redirect({
    from: 'checkout/:sku',
    to: initialForm,
  }),
  {
    path: 'checkout',
    getComponent: () => {
      return import('~/apps/Customer/pages/checkout/Checkout').then(
        (module) => module.default,
      );
    },
    children: checkoutRoutes,
  },
  {
    path: 'plans',
    getComponent: () => {
      return import('~/apps/Customer/pages/plans/Plans').then(
        (module) => module.default,
      );
    },
    getData: ({ location }) => {
      const videoId = location.query.video_id;
      return { videoId };
    },
  },
];

export default customerRoutes;
